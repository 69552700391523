import request from '@/utils/request'
/* 正式域名 */
const baseUrl = 'https://api.nanshikeji.cn'
/* 正式域名 */
/* 测试域名 */
// const baseUrl = 'http://localhost:89'
/* 测试域名 */
// 手机号密码登录
export function webLogin(data) {
  return request({
    url: '/user/Login/studentLogin',
    method: 'post',
    dataType: 'json',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 微信登录
export function wechatLogin(data) {
  return request({
    url: '/user/Login/wechatLogin',
    method: 'post',
    dataType: 'json',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 获取当前课程
export function getClassNow(data) {
  return request({
    url: '/user/User/getClassNow',
    method: 'post',
    dataType: 'json',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 获取上一个课程
export function getClassPrevious(data) {
  return request({
    url: '/user/User/getClassPrevious',
    method: 'post',
    dataType: 'json',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 获取下一个课程
export function getClassNext(data) {
  return request({
    url: '/user/User/getClassNext',
    method: 'post',
    dataType: 'json',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 获取课程统计
export function getStaticInfo(data) {
    return request({
        url: '/user/User/getStaticInfo',
        method: 'post',
        dataType: 'json',
        data,
        headers: { 'Content-Type': 'application/json' },
        baseURL: baseUrl
    })
}
// 获取课程详情
export function getClassInfo(data) {
  return request({
    url: '/user/User/getClassOne',
    method: 'post',
    dataType: 'json',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 获取课程进度
export function getSchedule(data) {
  return request({
    url: '/user/User/getSchedule',
    method: 'post',
    dataType: 'json',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 获取用户课程代码进度
export function getScheduleUrl(data) {
  return request({
    url: '/user/User/getScheduleUrl',
    method: 'post',
    dataType: 'json',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 更新课程进度
export function updateSchedule(data) {
  return request({
    url: '/user/User/updateSchedule',
    method: 'post',
    dataType: 'json',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 更新用户操作信息
export function updateEvent(data) {
  return request({
    url: '/user/User/updateEvent',
    method: 'post',
    dataType: 'json',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 获取聊天记录
export function getChatList(data) {
  return request({
    url: '/user/User/getChatList',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 获取当前用户信息
export function getUserInfoNow(data) {
  return request({
    url: '/user/User/getUserInfoNow',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 发送聊天图片
export function uploadImg(data) {
  return request({
    url: '/user/Upload/uploadImg',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
    baseURL: baseUrl
  })
}
// 上传语音文件
export function uploadWav(data) {
  return request({
    url: '/manage/Upload/uploadWav',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
    baseURL: baseUrl
  })
}
// 获取答题数据
export function getAnswerInfo(data) {
  return request({
    url: '/user/User/getAnswerInfo',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 提交答题数据
export function submitAnswer(data) {
  return request({
    url: '/user/User/submitAnswer',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 获取课程列表
export function getClassList(data) {
  return request({
    url: '/user/Course/getClassList',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 获取课程数量
export function getClassNum(data) {
  return request({
    url: '/user/Course/getClassNum',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseUrl
  })
}
// 获取课程列表
export function getWorks(data) {
    return request({
        url: '/user/User/getWorks',
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json' },
        baseURL: baseUrl
    })
}
