<template>
    <div class="main">
        <div class="login-box">
            <img v-if="isMobile === false" class="logo-img" src="../../assets/image/logo.png"/>
            <div class="login-info">
                <div class="input-box">
                    <input v-model="postData.tel" class="login-input" placeholder="请输入手机号"/>
                </div>
                <div class="input-box">
                    <input v-model="postData.password" class="login-input" placeholder="请输入密码"/>
                </div>
                <div class="login-button" @click="login()">登录</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        webLogin
    } from '@/api/app'
    import screenfull from 'screenfull'
    export default {
        name: 'webLogin',
        data() {
            return {
                postData: {
                    tel:'',
                    password:'',
                    isMobile: false
                }
            }},
        created() {
            let query=this.$route.query;
            console.log(query)
            this.checkDevice()
        },
        mounted:function(){
        },
        methods: {
            // 判断是手机端 还是电脑端
            checkDevice() {
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                // 对于iPhone的检测
                const isiPhone = userAgent.includes("iPhone");
                // 对于Android的检测
                const isAndroid = userAgent.includes("Android");
                // 对于基于Webkit内核浏览器的检测（Chrome, Safari等）
                // const isWebkit = userAgent.includes("AppleWebKit");
                // 对于PC端的检测
                const isPC = userAgent.includes("Windows") || userAgent.includes("Macintosh") || userAgent.includes("X11") || userAgent.includes("Linux");
                if (isiPhone || isAndroid) {
                    this.isMobile = true
                } else if (isPC) {
                    this.isMobile = false
                } else {
                    this.isMobile = false
                }
            },
            // 用户网页授权登录
            login(){
                // 判断是否支持全屏
                if (screenfull.isEnabled) {
                    screenfull.request()
                }
                // let _this = this
                if(this.postData.tel === '') {
                    this.$message({message: '请先输入手机号', type: 'error',duration: 3000});
                    return;
                }
                if(this.postData.password === '') {
                    this.$message({message: '请先输入密码', type: 'error',duration: 3000});
                    return;
                }
                const loading = this.$loading({
                    lock: true,
                    text: '登陆中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                webLogin(this.postData).then(res => {
                    loading.close();
                    if(res.code === 200) {
                        // 存储用户信息
                        sessionStorage.setItem('token',res.data.token)
                        sessionStorage.setItem('userInfo',JSON.stringify(res.data))
                        // 用户websocket登录
                        // let ws = _this.$globalWebSocket.ws
                        // let sendData = {}
                        // sendData.type = 'userLogin'
                        // sendData.token = res.data.token
                        // let postJson = JSON.stringify(sendData)
                        // ws.send(postJson)
                        // 调转页面
                        let path = sessionStorage.getItem("redirect");
                        let query = sessionStorage.getItem("search");
                        if(!path){
                            path = '/home'
                        }
                        if (query !== undefined){
                            this.$router.push({path: path, query: JSON.parse(query)})
                        } else {
                            this.$router.push({path: path})
                        }
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .main{
        width: 100%;
        height: 100vh;
        position: relative;
        background-color: #3398f1;
        background: url('../../assets/image/login_bg.jpg')  no-repeat;
        background-position: center center;
        background-size:100% 100%;
        background-attachment:fixed;
    }
    .logo-img{
        width: 28vh;
        height: 10vh;
        margin: 3vh 0;
    }
    .login-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .login-info{
        width: 30rem;
        height: 20rem;
        border-radius: 3rem;
        border: 0.7rem solid #4292f6;
        background-color:rgba(255,255,255,0.5);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .input-box{
        width: 25rem;
        height: 4rem;
        line-height: 4rem;
        border: 2px solid #4292f6;
        border-radius: 1rem;
        display: flex;
        cursor: pointer;
    }
    .login-button{
        width: 25rem;
        height: 4rem;
        line-height: 4rem;
        border-radius:  1rem;
        background-color: #4292f6;
        color: #ffffff;
        font-weight: bold;
        font-size: 2rem;
        text-align: center;
        cursor: pointer;
    }
    .login-input{
        height: 4rem;
        width: 25rem;
        line-height: 4rem;
        font-size: 2rem;
        text-align: center;
    }
</style>
